import { RangedLoanPredictionResult } from '../../../../graphql/generated';

export type LoanPredictionAttributes = {
  likelihood: number;
  minInterestRate: number;
  maxInterestRate: number;
};

export const loanPredictionAttributes: (
  loanPrediction: RangedLoanPredictionResult,
  appliedAmount: number,
) => LoanPredictionAttributes = (loanPrediction, appliedAmount) => {
  const loanPredictionSpans = Object.fromEntries(
    loanPrediction.spans.map(span => [span.amount, span.loanPrediction]),
  );

  const hasPaymentRemark: boolean = loanPrediction.input.paymentRemark;

  const amount = Math.round((appliedAmount || 150_000) / 10_000) * 10_000;
  const spanIndex = Math.min(600_000, Math.max(10_000, amount));

  const likelihood = Math.round(loanPredictionSpans[spanIndex].likelihood);
  const minInterestRate = hasPaymentRemark
    ? 20
    : loanPredictionSpans[spanIndex].bounds.lower;
  const maxInterestRate = hasPaymentRemark
    ? 25
    : loanPredictionSpans[spanIndex].bounds.upper;

  return { likelihood, minInterestRate, maxInterestRate };
};
